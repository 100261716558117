<script setup lang="ts">
const props = defineProps<{
  title?: string;
  groupTitle?: string;
  type:
    | "text"
    | "select"
    | "radio"
    | "checkbox"
    | "textarea"
    | "email"
    | "url"
    | "number"
    | "date"
    | "password"
    | "funds";
  required?: boolean;
  placeholder?: string;
  options?: string[];
  customValidation?: (value: string | boolean) => Promise<string>;
  error?: string;
  autofocus?: boolean;
}>();

const model = defineModel<string | boolean>({
  default: "",
});

const errorModel = defineModel<string>("error", {
  default: "",
});

const element = ref<HTMLObjectElement>();

const checkboxValues = ref<string[]>([]);

watch(
  checkboxValues,
  (checkboxValues) => {
    if (props.type === "checkbox") {
      model.value = checkboxValues.join(", ");
    }
  },
  { deep: true },
);

watch(
  model,
  (model) => {
    if (props.type === "checkbox" && typeof model === "string") {
      checkboxValues.value = model.split(", ").filter(Boolean);
    }
  },
  { immediate: true },
);

const inputName = (Math.random() + 1).toString(36).substring(7);

const validate = async (event: Event) => {
  const eventTarget = event.target as HTMLInputElement | null;

  if (props.customValidation) {
    eventTarget?.setCustomValidity(await props.customValidation(eventTarget.value));
  } else if (props.type === "checkbox" && props.required) {
    eventTarget?.setCustomValidity(model.value ? "Please select a value" : "");
  }

  errorModel.value = eventTarget?.checkValidity() ? "" : (eventTarget?.validationMessage ?? "");
};

const invalid = (event: Event) => {
  errorModel.value = (
    event.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  ).validationMessage;
};
</script>

<template>
  <div>
    <div class="form-floating">
      <input
        v-if="['text', 'email', 'number', 'date', 'url', 'password', 'funds'].includes(type)"
        :id="title?.toLowerCase().replace(/ /g, '-').replace(/,/g, '') + '-input'"
        ref="element"
        v-model="model"
        :type="type === 'funds' ? 'number' : type"
        :required="!!required"
        :placeholder="placeholder"
        :autofocus="autofocus"
        :name="title"
        :aria-label="title"
        :class="{
          'border-error': error,
        }"
        @input="validate"
        @click="
          (event) => {
            if (type === 'url' && event.target && (event.target as HTMLInputElement).value === '')
              (event.target as HTMLInputElement).value = 'https://';
          }
        "
        @invalid.prevent="invalid"
      />
      <textarea
        v-if="type === 'textarea' && typeof model === 'string'"
        :id="title?.toLowerCase().replace(/ /g, '-').replace(/,/g, '') + '-input'"
        ref="element"
        v-model="model"
        :required="!!required"
        :placeholder="placeholder"
        :class="{
          'border-error': error,
        }"
        @input="validate"
        @invalid.prevent="invalid"
      />
      <label
        v-if="title"
        :for="title?.toLowerCase().replace(/ /g, '-').replace(/,/g, '') + '-input'"
      >
        {{ title }}
      </label>
    </div>
    <select
      v-if="type === 'select'"
      ref="element"
      v-model="model"
      class="border-0 text-[length:inherit] focus:ring-0"
      @input="validate"
      @invalid.prevent="invalid"
    >
      <option
        v-for="option in options"
        :key="option"
        :value="option"
      >
        {{ option }}
      </option>
    </select>

    <div v-if="type === 'radio' || type === 'checkbox'">
      <div
        v-if="options"
        class="flex flex-col gap-4 rounded-lg bg-white p-6"
      >
        <h4
          v-if="groupTitle"
          class="text-xl text-navy/40"
        >
          {{ groupTitle }}
        </h4>
        <div class="flex flex-col gap-3">
          <div
            v-for="option in options"
            :key="option"
            class="flex cursor-pointer items-center gap-3"
          >
            <input
              v-if="type === 'radio'"
              :id="option"
              ref="element"
              v-model="model"
              :type="type"
              :value="option"
              :name="inputName"
              class="size-5 cursor-pointer appearance-none rounded-full border-0 bg-white text-blue ring-1 ring-black/20 ring-offset-1 before:opacity-0 before:transition-opacity checked:ring-blue checked:before:opacity-100 focus:ring-1 focus:ring-blue focus:ring-offset-1"
              @input="validate"
              @invalid.prevent="invalid"
            />
            <input
              v-if="type === 'checkbox'"
              :id="option"
              ref="element"
              :checked="checkboxValues.includes(option)"
              :type="type"
              :value="option"
              class="size-5 cursor-pointer appearance-none rounded-md border-0 bg-white text-blue ring-1 ring-black/20 ring-offset-1 before:opacity-0 before:transition-opacity checked:ring-blue checked:before:opacity-100 focus:ring-1 focus:ring-blue focus:ring-offset-1"
              @change="
                (event) => {
                  if ((<HTMLInputElement>event.target)?.checked) {
                    checkboxValues.push(option);
                  }
                  else {
                    checkboxValues.splice(checkboxValues.indexOf(option), 1);
                  }
                  validate(event);
                }
              "
              @invalid.prevent="invalid"
            />
            <label
              :for="option"
              class="cursor-pointer"
            >
              {{ option }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      v-if="error"
      class="text-sm text-[red]"
    >
      {{ error }}
    </div> -->
  </div>
</template>
